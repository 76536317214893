<template>
  <ws-dialog
      v-model="display"
      :title="$t('ChangePassword')"
      @save="nextStep"
      @cancel="previousStep"
      cancel-action
      :save-text="saveButtonText"
      :cancel-text="cancelButtonText"

  >

    <!-- Password Change Instruction -->
    <div v-if="step === 0">
      <h5 class="text-center my-6"> {{  $t('PasswordChangeInformation')  }} </h5>
      <h4 class="text-center" :style="`color : ${wsACCENT}`"> {{ $store.state.auth.user.email }}</h4>
    </div>

    <!-- New password Input -->
    <div v-if="step === 1" >
      <ws-text-field
          :label="$t('PasswordResetCode')"
          v-model="entityData.code"
          :error="restoreCodeError"
          @input="restoreCodeError = false"
      />
      <ws-text-field
          class="mt-3"
          v-model="entityData.password"
          :label="$t('NewPassword')"
          :error="passwordError"
          @input="passwordError = false"
      />
      <ws-text-field
          class="mt-3"
          v-model="entityData.repeat_password"
          :label="$t('NewPasswordRepeat')"
          :error="passwordError"
          @input="passwordError = false"
      />
    </div>



  </ws-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "authPasswordChangeDialog",
  props : {
    value : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      restoreCodeError : false,
      passwordError : false,
      display : false,
      entityData : {},
      step : 0,
    }
  },
  computed : {
    saveButtonText() {
      return this.step === 0 ? this.$t('Next') : this.$t('Save')
    },
    cancelButtonText() {
      return this.step === 0 ? this.$t('Cancel') : this.$t('Back')
    },
  },
  watch : {
    display() {
      if ( this.display !== this.value ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.display !== this.value ) {
        this.display = this.value
      }
    }
  },
  methods : {
    ...mapActions('auth',[ 'REQUEST_PASSWORD','CHANGE_PASSWORD']),

    nextStep() {
      switch ( this.step ) {
        case 0 : return this.sendPasswordChangeRequest()
        case 1 : return this.changePassword()
      }
    },
    async sendPasswordChangeRequest() {

      let result = await this.REQUEST_PASSWORD({ email : this.$store.state.auth.user.email })
      if ( !result ) {
        return
      }
      this.step++;
    },
    async changePassword() {

      if ( !this.entityData.password  ) {
        this.passwordError = true
        this.notify(this.$t('EnterPassword') , 'warning' )
        return
      }

      if ( this.entityData.password !== this.entityData.repeat_password ) {
        this.passwordError = true
        this.notify(this.$t('PasswordRepeatNotMatch') , 'warning' )
        return
      }

      let data = {
        code : this.entityData.code,
        password : this.entityData.password,
        repeat_password : this.entityData.repeat_password,
        email : this.$store.state.auth.user.email
      }
      let result = await this.CHANGE_PASSWORD(data)
      if ( !result ) {
        this.restoreCodeError = true
        this.notify(this.$t('PasswordResetCodeError') , 'warning')
        return
      }
      this.notify(this.$t('PasswordChangedMessage'), 'success')
      this.$emit('input' , false)

    },

    previousStep() {
      switch ( this.step ) {
        case 0 : return this.$emit('input' , false)
        case 1 : this.step = 0 ;  return;
      }
    }
  },
  mounted() {
    this.display = this.value
  }
}
</script>

<style scoped>

</style>